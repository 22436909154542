<template>
  <div id="app" ref="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>
<style lang="sass">
#app
  height: 100vh
  overflow-y: auto
</style>
