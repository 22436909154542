const TOKEN_KEY = 'TOKEN'
const OPENID_KEY = 'OPENID'
const USER_TO_PATH_KEY = 'USER_TO_PATH'
const POSTER_KEY = 'POSTER_'

export const getAccessToken = () => {
    const token  = sessionStorage.getItem(TOKEN_KEY)
    return token ? token : ''
    // return token ? token : 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkYWJhaSIsImV4cCI6MTY0NTUxMzYzOH0.OGD7Xx_1b0htisAZegg3hz3cDb3aRbfm-XDEgedAFDruMrFXdHeQefB9cS-6_uHji-W50PX4De4ZgU5s0Gqg1g'
}

export const setAccessToken = (token) => {
    sessionStorage.setItem(TOKEN_KEY, token)
}

export const getOpenId = () => {
    const openId  = sessionStorage.getItem(OPENID_KEY)
    return openId ? openId : ''
}

export const setOpenId = (token) => {
    sessionStorage.setItem(OPENID_KEY, token)
}

export const getUserToPath = () => {
    const path  = sessionStorage.getItem(USER_TO_PATH_KEY)
    return path ? path : '/'
}

export const setUserToPath = (path) => {
    //console.log(path)
    sessionStorage.setItem(USER_TO_PATH_KEY, path)
    // sessionStorage.removeItem(USER_TO_PATH_KEY)
}

export const clearUserToPath = () => {
    sessionStorage.removeItem(USER_TO_PATH_KEY)
}
export const getPoster = (id) => {
    const openId  = sessionStorage.getItem(POSTER_KEY+id)
    return openId ? openId : ''
}

export const setPoster = (id, posterUrl) => {
    sessionStorage.setItem(POSTER_KEY+id, posterUrl)
}


export const getHrefUri = (redirect='') => {
    const {protocol, host} = window.location
    return protocol +'//'+ host + redirect
}
