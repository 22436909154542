import Vue from 'vue'
import VueRouter from 'vue-router'
import '../utils/wechat'
import wechat from "../utils/wechat";

Vue.use(VueRouter)

const routes = [
  {
    path: '/activity-detail/:activityId(\\d+)',
    component: () => import('@/view/Home.vue'),
    name: 'activity-detail',
    meta: {
      title: '活动详情',
      icon: '',
      auth: true,
    }
  },
  {
    path: '/activity-share-detail/:shareId(\\d+)',
    component: () => import('@/view/Home.vue'),
    name: 'share-detail',
    meta: {
      title: '分享活动详情',
      icon: '',
      auth: true,
    }
  },
  {
    path: '/oauth',
    component: () => import('@/view/Oauth.vue'),
    meta: {
      title: '微信授权中',
      icon: '',
      auth: false,
    }
  },
  {
    path: '/poster/:id(\\d+)',
    component: () => import('@/view/Poster.vue'),
    name: 'poster',
    meta: {
      title: '保存海报',
      icon: '',
      auth: true,
    }
  },
  {
    path: '/common',
    component: {render: (e) => e("router-view")},
    name: 'common',
    meta: {
      title: '活动分享',
      icon: '',
      auth: true,
    },
    children:[
      {
        path: 'activity-detail/:activityId(\\d+)',
        component: () => import('@/view/common/Home.vue'),
        name: 'activity-detail',
        meta: {
          title: '活动详情',
          icon: '',
          auth: true,
        }
      },
      {
        path: 'activity-share-detail/:shareId(\\d+)',
        component: () => import('@/view/common/Home.vue'),
        name: 'share-detail',
        meta: {
          title: '分享活动详情',
          icon: '',
          auth: true,
        }
      },
    ]
  },
  {
    path: '/404',
    component: () => import('@/view/404.vue'),
    name: '404',
    meta: {
      title: '404',
      icon: '',
      auth: false,
    }
  },
  {
    path: '*',
    component: () => import('@/view/404.vue'),
    meta: {
      title: 'app',
      icon: '',
      auth: false,
    }
  }
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  //console.log(to)
  /*console.log(to.fullPath, to.params, to.path)
  console.log(from.fullPath, from.params)

  console.log(window.location.href)
  console.log(to.fullPath)*/
  if(to.meta && to.meta.auth && !wechat.checkToken()){
    wechat.oauth('snsapi_base', to.fullPath)
  }else{
    next()
  }


})


export default router
